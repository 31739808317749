// @flow
import * as React from 'react';
import useAppBarActions from './useAppBarActions';

import sanitizePrismicBackgroundColors from '~components/Header/helpers/sanitizePrismicBackgroundColors';
import { hasNodeImageValue } from '~plugins/prismic/components/NodeImage';
import type { PrismicImageType } from '~schema';

export type Config = {|
  overlayColor: ?string,
  backgroundImage: ?PrismicImageType,
|};

export default function useSetAppBarState(config: Config) {
  const { mergeState } = useAppBarActions();
  React.useLayoutEffect(() => {
    const overlayColor = sanitizePrismicBackgroundColors(config.overlayColor);
    const hasBackgroundImage = hasNodeImageValue(config.backgroundImage);
    mergeState({
      linksColor:
        (overlayColor &&
          hasBackgroundImage &&
          {
            Primary: 'white',
            White: 'default',
            Black: 'white',
          }[overlayColor]) ||
        'default',
    });
  }, [config.overlayColor, config.backgroundImage]);
}
